<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    }
  },
  data() {
    return {
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  },
  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    paginationInfo() {
      return this.$store.getters["WEBSITE_COMMENTS/paginationInfo"];
    }
  },
  methods: {
    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
