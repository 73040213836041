<template>
  <div v-if="item">
    <v-card flat>
      <v-card-title class="font-weight-bold pa-5">
        Thông tin chung
      </v-card-title>
      <v-container class="px-5 pt-0">
        <v-row>
          <v-col class="py-0" cols="8">
            <div class="font-weight-bold mb-2">Ảnh bìa</div>
            <tp-input-avatar
              :aspect-ratio="2"
              :src="
                item.cover && item.cover.slice(0, 7) === 'backend'
                  ? item.cover
                  : undefined
              "
              max-width="100%"
              @change="item.cover = $event"
              @updateFormData="updateFormDataCover($event)"
            ></tp-input-avatar>
          </v-col>
          <v-col class="py-0" cols="4">
            <div class="font-weight-bold mb-2">Logo</div>
            <tp-input-avatar
              :height="!item.icon ? 'calc(100% - 29px)' : undefined"
              :src="
                item.icon && item.icon.slice(0, 7) === 'backend'
                  ? item.icon
                  : undefined
              "
              max-height="100%"
              max-width="100%"
              @change="item.icon = $event"
              @updateFormData="updateFormDataIcon($event)"
            ></tp-input-avatar>
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="py-0" cols="8">
            <div class="font-weight-bold mb-2">Tên thương hiệu</div>
            <v-text-field
              v-model="item.name"
              class="text-body-1"
              dense
              flat
              outlined
              placeholder="Nhập tên thương hiệu"
              single-line
              solo
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="4">
            <div class="font-weight-bold mb-2">Ưu tiên hiển thị</div>
            <v-text-field
              v-model="item.priority"
              class="text-body-1"
              dense
              flat
              outlined
              placeholder="Nhập mức ưu tiên"
              single-line
              solo
              type="number"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Loại sản phẩm</div>
            <v-autocomplete
              v-model="item.cate_ids"
              :items="productTypes"
              chips
              class="tp-filter-autocomplete"
              deletable-chips
              dense
              hide-selected
              item-text="name"
              item-value="id"
              multiple
              no-data-text="Không có dữ liệu"
              outlined
              placeholder="Chọn loại sản phẩm"
              single-line
              small-chips
            ></v-autocomplete>
          </v-col>
          <v-col v-if="type && type === 'brand'" class="py-0">
            <div class="font-weight-bold mb-2">Thương hiệu chính hãng</div>
            <v-checkbox
              v-model="item.isAuthentic"
              :false-value="0"
              :true-value="1"
              class="mt-0"
              label="Chính hãng"
            ></v-checkbox>
            <v-textarea
              v-model="item.noteAuthentic"
              :disabled="item.isAuthentic === 0"
              background-color="white"
              class="text-body-1"
              counter
              dense
              filled
              maxlength="320"
              outlined
              placeholder="Nhập ghi chú cho thương hiệu chính hãng"
              required
              single-line
            ></v-textarea>
          </v-col>
          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Mô tả</div>
            <v-textarea
              v-model="item.description"
              background-color="white"
              class="text-body-1"
              dense
              filled
              outlined
              placeholder="Nhập mô tả"
              required
              single-line
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- End: General info -->
    <!-- Start: SEO info -->
    <v-card class="mt-5" flat>
      <v-card-title class="font-weight-bold pa-5">
        Thông tin SEO
      </v-card-title>
      <v-container class="px-5 pt-0">
        <v-row class="pb-5">
          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Tiêu đề trang</div>
            <v-text-field
              v-model="item.seo_data.title"
              background-color="white"
              class="text-body-1"
              counter
              dense
              filled
              maxlength="120"
              outlined
              placeholder="Nhập tiêu đề trang"
              required
              single-line
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Mô tả trang</div>
            <v-textarea
              v-model="item.seo_data.description"
              background-color="white"
              class="text-body-1"
              counter
              dense
              filled
              maxlength="320"
              outlined
              placeholder="Nhập mô tả trang"
              required
              single-line
            ></v-textarea>
          </v-col>
          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Từ khóa sản phẩm</div>
            <v-combobox
              v-model="item.seo_data.tags"
              class="tp-combobox text-body-1"
              deletable-chips
              dense
              hide-details
              hide-selected
              multiple
              outlined
              placeholder="Nhập từ khóa sản phẩm"
              single-line
              small-chips
            >
            </v-combobox>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- End: SEO info -->
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {
    productTypes() {
      return this.$store.getters["PRODUCT_TYPE/allProductTypes"];
    }
  },
  async created() {
    if (this.productTypes.length === 0) {
      await this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");
    }
  },
  methods: {
    updateFormDataCover(val) {
      this.$emit("updateFormDataCover", val);
    },

    updateFormDataIcon(val) {
      this.$emit("updateFormDataIcon", val);
    }
  }
};
</script>
