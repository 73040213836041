<template>
  <div class="sub-brand-wrapper">
    <div class="top d_flex">
      <v-btn
        class="white rounded-lg px-8 my-3 mr-3"
        depressed
        color="primary"
        outlined
        @click="pushSubBrand()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <div class="sub-brand-list py-3 pr-3">
        <v-hover
          v-slot:default="{ hover }"
          v-for="(item, index) in brand.sub_brands"
          :key="index"
        >
          <v-badge
            class="sub-brand-item"
            color="error"
            icon="mdi-trash-can-outline"
            overlap
            :value="hover"
          >
            <v-icon slot="badge" @click="deleteSubBrand(item, index)">
              mdi-trash-can-outline
            </v-icon>
            <v-chip
              class="sub-brand-chip white rounded-lg px-4 py-2"
              :class="{ 'cyan lighten-4': activeSubBrand === index }"
              @click.stop="showSubBrand(item, index)"
            >
              {{ item.name }}
            </v-chip>
          </v-badge>
        </v-hover>
      </div>
    </div>

    <v-card
      v-if="brand.sub_brands.length === 0"
      class="card-empty grey lighten-4"
      flat
    >
      <div class="grey--text text--darken-1">Không có dữ liệu</div>
    </v-card>

    <form-brand
      v-if="brand.sub_brands.length > 0"
      class="mt-5"
      :item="subBrand"
      @updateFormDataCover="formDataCover = $event"
      @updateFormDataIcon="formDataIcon = $event"
    ></form-brand>

    <v-btn
      v-if="brand.sub_brands.length > 0 && subBrand.id"
      class="rounded-lg mt-5 mr-2"
      depressed
      color="primary"
      :loading="brandStatusRequest.value === 'loading-updateSubBrand'"
      @click="updateSubBrand()"
    >
      Lưu
    </v-btn>
    <v-tooltip
      v-if="brand.sub_brands.length > 0 && !subBrand.id"
      right
      color="red lighten-2"
      content-class="font-weight-medium"
      :disabled="brand.id ? true : false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="rounded-lg mt-5 mr-2"
          depressed
          color="primary"
          v-on="on"
          v-bind="attrs"
          :loading="brandStatusRequest.value === 'loading-createSubBrand'"
          @click="brand.id ? createSubBrand() : null"
        >
          Thêm
        </v-btn>
      </template>
      <span>Bạn phải thêm thương hiệu trước</span>
    </v-tooltip>
  </div>
</template>

<script>
import FormBrand from "./FormBrand";

export default {
  components: {
    FormBrand
  },
  props: {
    activeSubBrand: {
      type: Number
    }
  },
  data() {
    return {
      formDataCover: null,
      formDataIcon: null
    };
  },
  computed: {
    brand() {
      return this.$store.getters["BRAND/brand"];
    },
    brandStatusRequest() {
      return this.$store.getters["BRAND/statusRequest"];
    },
    subBrand() {
      return this.$store.getters["BRAND/subBrand"];
    },
    uploadedCover() {
      return this.$store.getters["BRAND/uploadedCover"];
    },
    uploadedIcon() {
      return this.$store.getters["BRAND/uploadedIcon"];
    }
  },
  methods: {
    async createSubBrand() {
      this.subBrand.brand_id = this.brand.id;
      // Upload cover
      await this.uploadCover();
      this.subBrand.cover = this.uploadedCover;
      // Upload icon
      await this.uploadIcon();
      this.subBrand.icon = this.uploadedIcon;
      // Create request
      await this.$store.dispatch("BRAND/createSubBrand", this.subBrand);
      // Alert
      if (this.brandStatusRequest.value === "success-createSubBrand") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm mới thành công"
          }
        });
      }
    },

    deleteSubBrand(item, index) {
      if (!item.id) {
        this.brand.sub_brands.splice(index, 1);
        if (this.activeSubBrand === index) {
          this.$emit("updateActiveSubBrand", 0);
          if (this.brand.sub_brands.length > 0) {
            this.showSubBrand(item, index);
          }
        }
      } else {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span>Xóa thương hiệu con <strong>"${item.name}"</strong></span>`,
            message:
              "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
            confirmBtn: {
              color: "red accent-2",
              text: "Xóa",
              onClickHandler: async () => {
                await this.$store.dispatch("BRAND/deleteSubBrand", {
                  id: item.id
                });
                if (
                  this.brandStatusRequest.value === "success-deleteSubBrand"
                ) {
                  this.brand.sub_brands.splice(index, 1);
                  if (this.brand.sub_brands.length > 0) {
                    this.showSubBrand(item, 0);
                  } else {
                    await this.$store.dispatch("BRAND/resetSubBrand");
                  }
                  // Alert
                  this.$toast.show({
                    name: "toast-action-alert",
                    payload: {
                      message: "Đã xóa thành công"
                    }
                  });
                }
              }
            }
          }
        });
      }
    },

    pushSubBrand() {
      this.$emit("updateActiveSubBrand", this.activeSubBrand + 1);
      this.brand.sub_brands.unshift({
        cover: null,
        icon: null,
        name: `Thương hiệu con ${this.brand.sub_brands.length + 1}`,
        priority: null,
        description: null,
        seo_data: {
          title: null,
          description: null,
          tags: []
        }
      });
    },

    async showSubBrand(item, index) {
      this.$emit("updateActiveSubBrand", index);

      if (item.id) {
        await this.$store.dispatch("BRAND/getSubBrandById", item.id);
      } else {
        await this.$store.dispatch("BRAND/setSubBrand", item);
      }
    },

    async updateSubBrand() {
      // Check cover change
      if (this.subBrand.cover.slice(0, 7) !== "backend") {
        await this.uploadCover();
        // Set uploaded cover
        this.subBrand.cover = this.uploadedCover;
      }
      // Check icon change
      if (this.subBrand.icon.slice(0, 7) !== "backend") {
        await this.uploadIcon();
        // Set uploaded icon
        this.subBrand.icon = this.uploadedIcon;
      }
      // Create request
      await this.$store.dispatch("BRAND/updateSubBrand", this.subBrand);
      // Alert
      if (this.brandStatusRequest.value === "success-updateSubBrand") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu thay đổi"
          }
        });
      }
    },

    async uploadCover() {
      await this.$store.dispatch("BRAND/uploadCover", this.formDataCover);
    },

    async uploadIcon() {
      await this.$store.dispatch("BRAND/uploadIcon", this.formDataIcon);
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-brand {
  &-wrapper {
    height: 100%;
  }
  &-list {
    display: flex;
    max-width: calc(100% - 90px);
    overflow: scroll;
  }
  &-item {
    cursor: pointer;
    margin-left: 12px;
    &:first-child {
      margin-left: 0 !important;
    }
  }
  &-chip {
    cursor: pointer;
    height: 36px;
  }
}
.card-empty {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
}
</style>
