<template>
  <div>
    <form-brand
      :item="brand"
      type="brand"
      @updateFormDataCover="formDataCover = $event"
      @updateFormDataIcon="formDataIcon = $event"
    ></form-brand>
    <v-btn
      v-if="brand.id"
      class="rounded-lg mt-5 mr-2"
      depressed
      color="primary"
      :disabled="brandStatusRequest.value === 'loading-updateBrand'"
      :loading="
        brandStatusRequest.value === 'loading-createBrand' ||
          brandStatusRequest.value === 'loading-uploadCover' ||
          brandStatusRequest.value === 'loading-uploadIcon'
      "
      @click="updateBrand()"
    >
      Lưu
    </v-btn>
    <v-btn
      v-else
      class="rounded-lg mt-5 mr-2"
      depressed
      color="primary"
      :disabled="brandStatusRequest.value === 'loading-createBrand'"
      :loading="
        brandStatusRequest.value === 'loading-createBrand' ||
          brandStatusRequest.value === 'loading-uploadCover' ||
          brandStatusRequest.value === 'loading-uploadIcon'
      "
      @click="createBrand()"
    >
      Thêm
    </v-btn>
  </div>
</template>

<script>
import FormBrand from "./FormBrand";

export default {
  data() {
    return {
      formDataCover: null,
      formDataIcon: null
    };
  },
  components: {
    FormBrand
  },
  computed: {
    brand() {
      return this.$store.getters["BRAND/brand"];
    },
    brandStatusRequest() {
      return this.$store.getters["BRAND/statusRequest"];
    },
    uploadedCover() {
      return this.$store.getters["BRAND/uploadedCover"];
    },
    uploadedIcon() {
      return this.$store.getters["BRAND/uploadedIcon"];
    }
  },
  methods: {
    async createBrand() {
      await this.$store.dispatch("BRAND/handleLoadingBrand");
      const routeQuery = this.$route.query;

      // Upload cover
      await this.uploadCover();
      this.brand.cover = this.uploadedCover;
      // Upload icon
      await this.uploadIcon();
      this.brand.icon = this.uploadedIcon;
      // Create request
      await this.$store.dispatch("BRAND/createBrand", {
        data: this.brand,
        routeQuery: {
          search: routeQuery.search,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (this.brandStatusRequest.value === "success-createBrand") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm mới thành công"
          }
        });
      }
    },

    async updateBrand() {
      await this.$store.dispatch("BRAND/handleLoadingBrand");
      const routeQuery = this.$route.query;

      // Check cover change
      if (this.brand.cover.slice(0, 7) !== "backend") {
        await this.uploadCover();
        // Set uploaded cover
        this.brand.cover = this.uploadedCover;
      }
      // Check icon change
      if (this.brand.icon.slice(0, 7) !== "backend") {
        await this.uploadIcon();
        // Set uploaded icon
        this.brand.icon = this.uploadedIcon;
      }
      // Create request
      await this.$store.dispatch("BRAND/updateBrand", {
        data: this.brand,
        routeQuery: {
          search: routeQuery.search,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (this.brandStatusRequest.value === "success-updateBrand") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu thay đổi"
          }
        });
      }
    },

    async uploadCover() {
      await this.$store.dispatch("BRAND/uploadCover", this.formDataCover);
    },

    async uploadIcon() {
      await this.$store.dispatch("BRAND/uploadIcon", this.formDataIcon);
    }
  }
};
</script>
