<template>
  <v-data-table
    class="scrollable tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="
      websiteCommentStatusRequest.value === 'loading-getWebsiteComments'
    "
    loading-text="Đang tải dữ liệu"
    :items="websiteComments"
    item-key="id"
  >
    <template v-slot:[`item.content`]="{ item }">
      <a class="text--primary" :href="item.url" target="_blank">{{
        item.content
      }}</a>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Nội dung",
          align: "start",
          sortable: false,
          value: "content"
        },
        {
          text: "Khách hàng",
          align: "start",
          sortable: false,
          value: "user_name"
        },
        {
          text: "Số điện thoại",
          align: "start",
          sortable: false,
          value: "user_phone"
        },
        {
          text: "Thời gian tạo",
          align: "start",
          sortable: false,
          value: "time"
        }
      ]
    };
  },

  computed: {
    websiteComments() {
      return this.$store.getters["WEBSITE_COMMENTS/websiteComments"];
    },
    websiteCommentStatusRequest() {
      return this.$store.getters["WEBSITE_COMMENTS/statusRequest"];
    }
  }
};
</script>

<style lang="scss" scoped>
.scrollable {
  max-height: calc(100vh - 64px - 53px - 24px - 102.38px);
  overflow-y: scroll;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 24px - 102.38px);
  }
}
</style>
