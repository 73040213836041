<template>
  <tp-modal name="modal-brand-detail" width="70%" max-width="960px">
    <v-card>
      <v-toolbar
        class="px-5 pb-0 align-start"
        height="56x"
        extension-height="40px"
      >
        <v-toolbar-title class="font-weight-bold">
          Thương hiệu
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn v-if="brand.id" icon color="red accent-1" @click="deleteBrand()">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs v-model="activeTab" height="40px">
            <v-tab
              class="font-weight-bold"
              v-for="item in tabItems"
              :key="item.id"
            >
              {{ item.text }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items class="modal-body-scroll" v-model="activeTab">
        <v-tab-item class="tab-item grey lighten-3 pa-5" key="general">
          <tab-general></tab-general>
        </v-tab-item>
        <v-tab-item class="tab-item grey lighten-3 pa-5" key="sub-brand">
          <tab-sub-brand
            :active-sub-brand="activeSubBrand"
            @updateActiveSubBrand="activeSubBrand = $event"
          ></tab-sub-brand>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </tp-modal>
</template>

<script>
import TabGeneral from "./components/TabGeneral";
import TabSubBrand from "./components/TabSubBrand";

export default {
  components: {
    TabGeneral,
    TabSubBrand
  },
  data() {
    return {
      activeSubBrand: 0,
      activeTab: null,
      tabItems: [
        { id: "general", text: "Chung" },
        { id: "sub-brand", text: "Thương hiệu con" }
      ]
    };
  },
  computed: {
    brand() {
      return this.$store.getters["BRAND/brand"];
    },
    brandStatusRequest() {
      return this.$store.getters["BRAND/statusRequest"];
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch("BRAND/resetBrand");
      this.$store.dispatch("BRAND/resetSubBrand");
      this.$modal.hide({
        name: "modal-brand-detail"
      });
      this.activeTab = null;
      this.activeSubBrand = 0;
    },

    deleteBrand() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa thương hiệu <strong>"${this.brand.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch("BRAND/deleteBrands", {
                data: {
                  ids: [this.brand.id]
                },
                routeQuery: {
                  search: routeQuery.search,
                  per_page: parseInt(routeQuery.limit),
                  cur_page: parseInt(routeQuery.page)
                }
              });
              if (this.brandStatusRequest.value === "success-deleteBrands") {
                this.$modal.hide({ name: "modal-brand-detail" });
                this.$store.dispatch("BRAND/resetBrand");
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              }
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-item {
  min-height: 500px;
}
</style>
